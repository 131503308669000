import useFetch from './useFetch'

const useSendRestEmail = ({ identityId, onSuccess, onFailure }) => {
  const { fetch: sendResetEmail, isLoading: isResetEamilLoading } = useFetch({
    url: `/pin/reset/mail?identityId=${identityId}`,
    method: 'post',
    onSuccess: onSuccess,
    onFailure: onFailure,
  })

  return { sendResetEmail, isResetEamilLoading }
}

export default useSendRestEmail
