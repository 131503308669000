import styled from '@emotion/styled'

export const Spinner = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20%;
    animation: rotate 4s linear infinite;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }
`
