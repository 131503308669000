const token = 'token'

class TokenManager {
  constructor() {
    this.initToken()
  }

  initToken() {
    if (typeof window === 'undefined') return
    this._token = sessionStorage.getItem(token)
  }

  setToken(tokens) {
    this._token = tokens.token
    sessionStorage.setItem(token, tokens.token)
  }

  deleteToken() {
    sessionStorage.removeItem(token)
  }

  get token() {
    return this._token
  }
}

export default TokenManager
