import { useEffect, useRef } from 'react'

const Canvas = ({ artBuffer }) => {
  const canvasRef = useRef(null)

  const handleImageChange = (arrayBuffer) => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    const blob = new Blob([arrayBuffer])
    const image = new Image()
    image.src = URL.createObjectURL(blob)

    image.onload = function () {
      // Canvas 크기에 맞게 이미지의 원본 비율로 출력
      const canvasAspectRatio = canvas.width / canvas.height
      const imageAspectRatio = image.width / image.height

      let drawWidth, drawHeight

      // 이미지의 가로가 Canvas의 가로보다 큰 경우
      if (imageAspectRatio > canvasAspectRatio) {
        drawWidth = canvas.width
        drawHeight = canvas.width / imageAspectRatio
      } else {
        // 이미지의 세로가 Canvas의 세로보다 큰 경우
        drawWidth = canvas.height * imageAspectRatio
        drawHeight = canvas.height
      }

      // Canvas에 이미지 그리기 (가운데 정렬)
      const drawX = (canvas.width - drawWidth) / 2
      const drawY = (canvas.height - drawHeight) / 2

      context.drawImage(image, drawX, drawY, drawWidth, drawHeight)
    }
  }

  useEffect(() => {
    var binaryString = atob(artBuffer)
    var bytes = new Uint8Array(binaryString.length)
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    handleImageChange(bytes.buffer)
  }, [])

  return <canvas ref={canvasRef} width={300} height={300} />
}

export default Canvas
