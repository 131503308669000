import * as S from './style'
import { useRecoilState, useRecoilValue } from 'recoil'
import { TabListInfo, TabMode } from '../../../Atom/Atom'

export default function Tabs() {
  const TabList = Object.entries(useRecoilValue(TabListInfo))
  const [focus, setFocus] = useRecoilState(TabMode)

  return (
    <S.TabsWrapper>
      <div>
        {TabList.map(([key, value]) => (
          <S.TabBox
            id={key}
            key={key}
            selected={key === focus}
            onClick={() => setFocus(key)}
          >
            {value}
          </S.TabBox>
        ))}
      </div>
    </S.TabsWrapper>
  )
}
