import styled from '@emotion/styled'

export const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
`
export const ImgBox = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 30px;

  /* transform: ${({ x }) => `translateX(${x}px)`}; */
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
`
