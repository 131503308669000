import * as S from './style'

export default function ArtistSign({ url }) {
  return (
    <S.ArtistSignWrapper>
      <div>
        <img src={url} alt='artistSign' />
      </div>
    </S.ArtistSignWrapper>
  )
}
