import useFetch from './useFetch'

const useSendInviteEmail = ({
  identityId,
  onSuccess,
  onFailure,
  isLoading,
}) => {
  const { fetch: sendInviteMail, isLoading: isInviteMailLoading } = useFetch({
    url: `/pin/send/mail?identityId=${identityId}`,
    method: 'post',
    onSuccess: onSuccess,
    onFailure: onFailure,
  })

  return { sendInviteMail, isInviteMailLoading }
}

export default useSendInviteEmail
