import { useEffect, useState } from 'react'
import * as S from './style'

export default function Slider({ slideList }) {
  const [windowSize, setWindowSize] = useState(window.innerWidth - 45)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth - 45)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return (
    <S.SliderWrapper>
      {slideList.map((url, index) => (
        <S.ImgBox key={index} x={index * windowSize}>
          <img src={url} alt='Sign img' />
        </S.ImgBox>
      ))}
    </S.SliderWrapper>
  )
}
