import { useEffect, useState } from 'react'
import * as S from './style'
import { useLocation, useNavigate } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { arrayEquals } from '../../util/arrayEquals'
import TokenManager from '../../api/TokenManger'

export default function Pin() {
  const padNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, NaN, 0]
  const pinNumber = [0, 1, 2, 3, 4, 5]
  const [pin, setPin] = useState([])
  const [doubleCheckPin, setDoubleCheckPin] = useState([])
  const navigate = useNavigate()

  const pinSetting = 'pinSetting',
    pintReEnter = 'pintReEnter',
    pinEnter = 'pinEnter'
  const { state } = useLocation()

  useEffect(() => {
    if (!state) navigate('/404')
  }, [])

  const { status, identityId } = state || {}
  console.log(identityId)
  const [pinInputState, setPinInputState] = useState('') //-5000 : pin enter, -4000 : pin setting

  useEffect(() => {
    if (status === '-5000') return setPinInputState(pinSetting)
    if (status === '-4000') return setPinInputState(pinEnter)
    navigate('/404')
  }, [])

  const setPinHandle = (props) => {
    if (pin.length >= 6) return
    setPin((prev) => [...prev, props])
  }

  useEffect(() => {
    if (pin.length === 6) onSubmit()
  }, [pin])

  const backSpaceHandle = () => {
    setPin((prev) => prev.slice(0, -1))
  }

  const inputStateMessage = {
    pinSetting: {
      btnMessage: '입력하기',
      title: (
        <p>
          PIN 초기 세팅을 위해
          <br />
          <strong>PIN 번호를 입력하세요</strong>
        </p>
      ),
    },
    pintReEnter: {
      btnMessage: '확인',
      title: (
        <p>
          PIN 번호 재 확인을 위해
          <br />
          <strong>PIN 번호를 다시 입력하세요</strong>
        </p>
      ),
    },
    pinEnter: {
      btnMessage: '인증하기',
      title: (
        <p>
          인증서를 확인하시려면
          <br />
          <strong>PIN 번호를 입력하세요</strong>
        </p>
      ),
    },
  }

  const { fetch: createPin } = useFetch({
    url: `/pin?identityId=${identityId}`,
    method: 'post',
    onSuccess: () => {
      setPin([])
      setPinInputState(pinEnter)
    },
  })
  const { fetch: signInPin } = useFetch({
    url: `/pin/login?identityId=${identityId}&pin=${pin.join('')}`,
    method: 'post',
    onSuccess: (data) => {
      const tokenManager = new TokenManager()
      tokenManager.setToken(data)
      navigate(`/certify?code=${identityId}`)
    },
    onFailure: () => {
      setPin([])
    },
  })

  const handleDoubleCheck = () => {
    if (pinInputState !== pintReEnter) {
      setDoubleCheckPin(pin)
      setPinInputState(pintReEnter)
      setPin([])
      return
    } else if (arrayEquals(doubleCheckPin, pin)) {
      createPin({ pin: pin.join('') })
      return
    } else {
      alert('세팅 번호와 다릅니다')
      setPin([])
    }
  }

  const onSubmit = () => {
    if (pin.length !== 6) return alert('핀 번호가 부족합니다.')
    if (pinInputState === pinEnter) return signInPin()
    return handleDoubleCheck()
  }

  return (
    <S.PinWrapper>
      <S.NumberViewWrapper>
        {pinInputState && inputStateMessage[pinInputState].title}
        <S.NumberWrapper>
          {pinNumber.map((_, idx) => (
            <div key={idx}>{pin[idx] !== undefined && '*'}</div>
          ))}
        </S.NumberWrapper>
        {pinInputState === pinEnter && (
          <div
            onClick={() =>
              navigate('/mail-code/reset', { state: { identityId } })
            }
          >
            PIN 번호 재설정
          </div>
        )}
      </S.NumberViewWrapper>
      <S.NumberPadWrapper>
        {padNumber.map((props) => {
          if (isNaN(props))
            return <div style={{ cursor: 'default' }} key={props} />
          return (
            <div key={props} onClick={() => setPinHandle(props)}>
              {props}
            </div>
          )
        })}
        <div onClick={backSpaceHandle}>
          <img
            src={`${process.env.PUBLIC_URL + '/svg/DeleteIcon.svg'}`}
            alt='deleteIcon'
          />
        </div>
      </S.NumberPadWrapper>
    </S.PinWrapper>
  )
}
