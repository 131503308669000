import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import CertifyPage from './pages/certify-page'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import ErrorPage from './pages/error-page'
import { RecoilRoot } from 'recoil'
import PinPage from './pages/pin'
import NotFouncPage from './pages/404'
import MailCodePage from './pages/mail-code'

const router = createBrowserRouter([
  {
    path: '/certify',
    element: <CertifyPage />,
  },
  {
    path: '/certify/of',
    element: <CertifyPage />,
  },
  {
    path: '/pin',
    element: <PinPage />,
  },
  {
    path: '/mail-code',
    element: <MailCodePage />,
  },
  {
    path: '/mail-code/reset',
    element: <MailCodePage />,
  },
  {
    path: '/mail-code/delete',
    element: <MailCodePage />,
  },
  {
    path: '/404',
    element: <NotFouncPage />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
    ,
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
