import styled from '@emotion/styled'

export const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`

export const Title = styled.h1`
  color: rgb(255, 255, 255);
  font-size: 20vw;
  margin: 0;
`
export const Message = styled.p`
  color: rgb(255, 255, 255);
  font-weight: 500;
`
