import styled from '@emotion/styled'

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    box-sizing: border-box;
    max-width: 390px;
    width: 100%;
    padding: 0 40px;
    color: white;
    > div {
      box-sizing: border-box;
      margin: 40px 0;
      display: flex;
      gap: 10px;

      > p {
        :nth-of-type(2) {
          flex: 1 0 0px;
          width: 1px;
          word-break: keep-all;
        }

        :nth-of-type(1) {
          width: 80px;
          font-weight: 600;
          color: rgb(255, 255, 255);
        }
      }

      > p[id='transactionHash'] {
        word-break: break-all;
      }
    }
  }
`
