import styled from '@emotion/styled'

export const ArtistSignWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 0 40px;
  > div {
    box-sizing: border-box;
    max-width: 390px;
    width: 100%;
    aspect-ratio: 1 / 0.564;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      filter: brightness(0) invert(1);
      width: 100%;
      aspect-ratio: 1 / 0.564;
      box-sizing: content-box;
      display: block;
      object-fit: contain;
      object-position: center;
    }
  }
`
