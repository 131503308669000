import { useRecoilState } from 'recoil'
import Certify from '../components/Certify'
import useFetch from '../hooks/useFetch'
import { ArtInfo } from '../Atom/Atom'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Spinner from '../components/common/Spinner'
import TokenManager from '../api/TokenManger'

export default function CertifyPage() {
  const [artInfo, setArtInfo] = useRecoilState(ArtInfo)
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const identityId = encodeURIComponent(searchParams.get('code'))
  const [isPin, setIsPin] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    checkPin()
  }, [])

  const handleGetDetail = () => {
    if (pathname === '/certify/of') getOfDetail()
    else getDetail()
  }

  const { fetch: checkPin } = useFetch({
    url: `/pin/exist?identityId=${identityId}`,
    method: 'get',
    onSuccess: ({ pinCodeExist }) => {
      if (pinCodeExist) {
        checkToken()
      } else {
        handleGetDetail()
      }
      setIsPin(pinCodeExist)
    },
    onFailure: () => navigate('/404'),
  })

  const { fetch: checkToken, isLoading } = useFetch({
    url: `/pin?identityId=${identityId}`,
    method: 'get',
    onSuccess: ({ status }) => {
      switch (status) {
        case '-4000':
          const tokenManager = new TokenManager()
          if (tokenManager.token) tokenManager.deleteToken()
          return navigate('/pin', { state: { identityId, status } })
        case '2000':
          return handleGetDetail()
        default:
          break
      }
    },
    onFailure: (e) => {
      if (e.response.status === 401) return checkPin()
      navigate('/404')
    },
  })

  const { fetch: getDetail } = useFetch({
    url: `/nft/detail?identityId=${identityId}`,
    method: 'get',
    onSuccess: setArtInfo,
    onFailure: () => navigate('/404'),
  })

  const { fetch: getOfDetail } = useFetch({
    url: `/nft/detail/of?identityId=${identityId}`,
    method: 'get',
    onSuccess: setArtInfo,
    onFailure: () => navigate('/404'),
  })

  return isLoading || !artInfo ? (
    <Spinner />
  ) : (
    <Certify isPin={isPin} identityId={identityId} />
  )
}
