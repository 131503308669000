import * as S from './style'

export default function Input({
  id,
  type,
  setValue,
  label,
  fixedDescription,
  regex,
  required,
  ...rest
}) {
  const onChange = (e) => {
    setValue(e)
  }
  return (
    <>
      <label htmlFor={id}>
        <span required={required}>{label}</span>
        <S.FixedTagWrapper>
          <input
            id={id}
            type={type}
            onChange={onChange}
            autoComplete='off'
            required={required}
            {...rest}
          />
          {fixedDescription && <S.FixedTag>{fixedDescription}</S.FixedTag>}
        </S.FixedTagWrapper>
      </label>
    </>
  )
}
