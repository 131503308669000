export const artwork = {
  title: '작품명',
  artist: '작가',
  country: '국가',
  size: '크기',
  materials: '분류',
  createdYear: '제작연도',
  editionNo: '에디션',
}

export const nft = {
  transactionHash: '트랜잭션',
  tokenId: '토큰 ID',
  standard: '토큰 표준',
  artist: '작가',
  chain: '체인',
  createdDate: '등록일',
  owner: '소유주',
}

export const authority = {
  name: '기관명',
  address: '주소',
  email: '메일',
}
