import axios from 'axios'

import TokenManager from './TokenManger'

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
})

API.interceptors.request.use(async (config) => {
  const tokenManager = new TokenManager()
  config.headers['Session-Token'] = tokenManager.token
    ? tokenManager.token
    : undefined

  return config
})

API.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const tokenManager = new TokenManager()

    if (err.response && err.response.status === 401) tokenManager.deleteToken()
    return Promise.reject(err)
  },
)

export default API
