import { useRecoilValue } from 'recoil'
import * as S from './style'
import { ArtInfo, TabMode } from '../../../Atom/Atom'
import * as ArtTabListName from '../../../Lib/ArtTabListName'
import ArtistSign from '../ArtistSign'

export default function InfoTab({ moreComponent }) {
  const tabMode = useRecoilValue(TabMode)
  const InfoLisis = useRecoilValue(ArtInfo)[tabMode]
  if (!InfoLisis) return <></>

  const { signImgUrl, ...rest } = InfoLisis

  return (
    <>
      <S.InfoWrapper>
        <div>
          {Object.entries({ ...rest }).map(
            ([key, value]) =>
              value && (
                <div key={key}>
                  <p>{ArtTabListName[tabMode][key] || key}</p>
                  <p id={key}>{value}</p>
                </div>
              ),
          )}
        </div>
      </S.InfoWrapper>
      {signImgUrl && <ArtistSign url={signImgUrl} />}
      {moreComponent[tabMode]}
    </>
  )
}
