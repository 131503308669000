import * as S from './style'

export default function NotFound() {
  return (
    <S.NotFoundWrapper>
      <S.Title>404</S.Title>
      <S.Message>Not Found Page...</S.Message>
    </S.NotFoundWrapper>
  )
}
