import styled from '@emotion/styled'

export const PinWrapper = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 0 30px;

  > div {
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
  }
`

export const NumberViewWrapper = styled.div`
  > p {
    font-size: 24px;
    font-weight: 200;
  }

  > button {
    width: 100%;
    height: 48px;
    color: #ffffff;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.1);
    margin-top: 32px;
    border: none;
    font-size: 20px;
    font-weight: 600;
  }

  > div {
    :nth-of-type(2) {
      margin-top: 20px;
      color: #9fa1a8;
    }
  }
`

export const NumberWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 48px;
  gap: 8px;

  > div {
    width: 100px;
    height: 48px;
    box-sizing: border-box;
    border-bottom: 1px solid #ffff;
    font-weight: 400;
    font-size: 40px;
  }
`

export const NumberPadWrapper = styled.div`
  height: 300px;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;

  > div {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
  }
`
