import { atom } from 'recoil'

export const TabListInfo = atom({
  key: 'TabListInfo',
  default: {
    artwork: '작품정보',
    nft: 'NFT',
    authority: '인증기관',
  },
})

export const ArtInfo = atom({
  key: 'ArtInfo',
  default: '',
})

export const TabMode = atom({
  key: 'TabMode',
  default: 'artwork',
})
