import styled from '@emotion/styled'

export const TabsWrapper = styled.div`
  width: 100%;
  height: auto;

  > div {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 8px;
    height: min-content;
    align-items: center;
    flex-direction: row;
  }
`

export const TabBox = styled.span`
  box-sizing: border-box;
  transform: translateY(2%);
  transition: all ease 0.3s;
  width: 100%;
  height: 100%;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  color: ${({ selected }) =>
    selected ? 'rgb(255, 255, 255)' : 'rgba(255, 255, 255, 0.3)'};
  border-bottom: ${({ selected }) =>
    selected ? '2px solid rgb(255, 255, 255)' : '2px solid rgb(0, 0, 0, 0)'};
  cursor: pointer;
`
