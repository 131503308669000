import styled from '@emotion/styled'

export const FixedTagWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
`

export const FixedTag = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #a6a6a6;
  font-size: 0.85em;
`
