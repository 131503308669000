import styled from '@emotion/styled'

export const AlertWrapper = styled.div`
  width: 350px;
  height: 240px;
  border-radius: 4px;
  background: rgba(23, 23, 23, 1);
  padding: 8px;
  display: flex;
  flex-direction: column;
`

export const AlertMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
`

export const BtnWrapper = styled.div`
  width: 100%;
  height: 60px;

  display: flex;
  gap: 8px;
`
export const Btn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 8px;
  color: #fff;
  background: rgba(30, 30, 30, 1);
`
