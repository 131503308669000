import * as S from './style'

export default function Alert({ onClose, alertMessage, onCheck }) {
  return (
    <S.AlertWrapper>
      <S.AlertMessage>{alertMessage}</S.AlertMessage>
      <S.BtnWrapper>
        <S.Btn onClick={onClose}>취소</S.Btn>
        <S.Btn onClick={onCheck}>확인</S.Btn>
      </S.BtnWrapper>
    </S.AlertWrapper>
  )
}
