import styled from '@emotion/styled'

export const Layer = styled.div`
  width: auto;
  min-height: 100vh;
  align-items: center;
  background-color: #1a1a1a;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 100px;
`

export const PinSettingBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding-top: 15px;
  padding-right: 15px;

  > button {
    width: 80px;
    height: 40px;
    border: 1px solid rgba(33, 33, 33, 0.1);
    border-radius: 8px;
    cursor: pointer;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
    font-weight: 600;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    color: rgba(255, 255, 255, 0.75);
  }
`

export const ProductWrapper = styled.div`
  width: 100%;
  height: min-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 30px;
`

export const LogoWrapper = styled.div`
  width: 120px;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;

  div {
    position: absolute;
    > img {
      width: 100%;
      height: auto;
      box-sizing: content-box;
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }
`
export const ProductImgWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PhotoToggleButton = styled.div`
  position: fixed;
  bottom: 30px;
  left: 50%;
  width: 160px;
  height: 48px;
  transform: translateX(-50%);
  align-items: center;
  background: #fff;
  border-radius: 120px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-weight: 700;
  color: rgb(71, 91, 222);
`
